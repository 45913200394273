<template>
  <div class="flex items-center gap-4">
    <div
      v-for="item in 2"
      :key="item"
      class="animation-slides flex items-center gap-4 w-fit"
    >
      <slot />
    </div>
  </div>
</template>

<script setup></script>

<style scope>
.animation-slides {
  animation: slide 20s linear infinite alternate;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100%));
  }
}
</style>
